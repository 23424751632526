import { Routes, Route } from "react-router-dom";
import './App.css';
import Layout from "./layout";
import Login from "./login";
import Register from "./register";
import Logout from "./logout";
import Products from "./products";
import Setup from "./setup";
import Product from "./product";
import Settings from "./settings";
import Pages from "./pages";
import Models from "./models";
import LandingPage from "./landingPage";
import Preview from "./preview";
import Flow from "./flow";
import 'flowbite';
import Designer from "./components/designer";
import Editor from "./editor";

export default function App() {
  return (<>
    <Routes>
      <Route path="/" element={<Products />} />
      <Route path="/setup" element={<Setup />} />
      <Route path="/editor/:id" element={<Editor />} />
      <Route path="/designer" element={<Designer />} />
      <Route path="/product" element={<Layout />}>
        <Route path="/product/:id" element={<Product />} />
        <Route path="/product/settings/:id" element={<Settings />} />
        <Route path="/product/landing/:id" element={<LandingPage />} />
        <Route path="/product/models/:id" element={<Models />} />
        <Route path="/product/pages/:id" element={<Pages />} />
      </Route>
      <Route path="/preview/:id" element={<Preview />} />
      <Route path="/app/:id" element={<Preview live={true} />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
    </Routes></>
  )
}

