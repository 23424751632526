import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Button from './components/button';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CogIcon,
  EyeIcon,
  PencilIcon,
  ShareIcon,
  TrashIcon,
  XCircleIcon,
  CheckIcon,
  ArrowDownIcon,
  CodeBracketIcon,
  ClipboardIcon,
  ClipboardDocumentIcon,
  ArrowUpIcon,
  DocumentIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentArrowDownIcon,
  InformationCircleIcon,
  EyeSlashIcon,
  LinkIcon
} from '@heroicons/react/24/outline'
import { PlusIcon, SparklesIcon } from '@heroicons/react/20/solid'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Settings from "./settings";
import TemplateRender from "./template-render";
import Confirm from "./components/confirm";
import { Disclosure, Menu } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from './vixba.png'
import Thumbnail from "./components/page-thumbnail";
import Guide from "./components/guide";

const guideSteps = [
  { id: 'beaadd3f-e570-5285-92ff-9dd12b9bdf3c', title: 'Generate a Prototype', description: '' },
  { id: '356b5566-a4c3-5d6c-9a76-ebb7ca57d429', title: 'Edit some content', description: 'Click on any text or image to edit your prototype content' },
  { id: 'c72aa263-38fe-5a61-886b-8da321b5a123', title: 'Add a new page', description: 'Click on the plus button next to your page list to add a new page' },
  { id: '0234d7f9-e920-5df6-a906-8c20b4e41cb7', title: 'Preview your prototype', description: 'Click on preview at the top right to view your prototype' },
  { id: '0b14724f-017d-5dff-b3f6-e7562368ff97', title: 'Share your prototype', description: 'Click on share at the top right to share your prototype with others' },
  { id: 'c2bf3b49-8dfe-5823-9888-316fb2f60596', title: '', description: '' }
];

export default function Editor() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;
  const navigate = useNavigate();
  const params = useParams();
  const [sParams, setSearchParams] = useSearchParams();
  const [product, setProduct] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [permissions, setPermissions] = useState({});

  const [open, setOpen] = useState(false)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const [createdModalOpen, setCreatedModalOpen] = useState(false)
  const [pageSettingsOpen, setPageSettingsOpen] = useState(false);

  const [publishOpen, setPublishOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);

  const [generatingContent, setGeneratingContent] = useState(false);
  const [pagesOpen, setPagesOpen] = useState(false);
  const [displayFireworks, setDisplayFireworks] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const [currentStep, setCurrentStep] = useState(localStorage.getItem("ec0436a4"));
  let stepIndex = guideSteps.findIndex(x => x.id === currentStep);
  if (stepIndex < 1) stepIndex = 1;
  const setStep = (step) => {
    console.log(step)
    let newStepIndex = guideSteps.findIndex(x => x.id == step) + 1;
    if (newStepIndex == stepIndex + 1) {
      localStorage.setItem("ec0436a4", guideSteps[newStepIndex].id);
      setCurrentStep(guideSteps[newStepIndex].id)
    }
  }

  const pageIndex = sParams.get("i") ?? 0;
  let page = product?.pages ? product?.pages[pageIndex] : null;

  const generatePageContent = async () => {
    setGeneratingContent(true);
    await fetch(`${process.env.REACT_APP_API_URL}/product/generate-page-content`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token"),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ product: product, page: page })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
      setGeneratingContent(false);
      setDisplayFireworks(true);
      setTimeout(() => setDisplayFireworks(false), 2000)
      setProduct({ ...product, pages: product.pages.map((y, i) => pageIndex == i ? { ...y, ...x.values, new: false } : y) });
      submitForce({ ...product, pages: product.pages.map((y, i) => pageIndex == i ? { ...y, ...x.values, new: false } : y) })

      if (sParams.get("n")) {
        sParams.delete("n");
        setCreatedModalOpen(true)
      }
    })
  }

  if (!generatingContent && page?.new && page?.template) generatePageContent();

  const setPageIndex = (index) => {
    if (index === false || index === null || index === undefined) setSearchParams({})
    setSearchParams({ i: index });
    scrollToTop();
  }

  useEffect(() => {
    async function fetchData() {
      await fetch(`${process.env.REACT_APP_API_URL}/product/permissions`, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'bearer ' + localStorage.getItem("token")
        })
      }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
        setPermissions(x.permissions);
      })
    }
    fetchData();
  }, [])

  async function fetchData() {
    await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token")
      })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
      setLoaded(true);
      setProduct(x.product);
    })
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    fetchData();
  }, [])

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
      method: 'PUT',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token"),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ product: product })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {

    })
  }

  const submitThenPreview = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
      method: 'PUT',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token"),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ product: product })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
      setStep('0234d7f9-e920-5df6-a906-8c20b4e41cb7')
      navigate('/preview/' + params.id + '?i=' + pageIndex);
    })
  }

  const submitForce = async (product) => {
    await fetch(`${process.env.REACT_APP_API_URL}/product/${params.id}`, {
      method: 'PUT',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token"),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ product: product })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {

    })
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let tempPages = [...product.pages];
    var element = tempPages[result.source.index];
    tempPages.splice(result.source.index, 1);
    tempPages.splice(result.destination.index, 0, element);
    setProduct({ ...product, pages: tempPages });
    submitForce({ ...product, pages: tempPages });

    if (result.source.index < pageIndex && result.destination.index >= pageIndex) setPageIndex(+pageIndex - 1)
    if (result.source.index > pageIndex && result.destination.index <= pageIndex) setPageIndex(+pageIndex + 1)
    if (result.source.index == pageIndex) setPageIndex(+result.destination.index)
  }

  const downloadProtoSource = async () => {
    setDownloadOpen(true)
    await fetch(`${process.env.REACT_APP_API_URL}/generate/proto-source/${params.id}`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token")
      })
    }).then(transfer => transfer.blob())
      .then(bytes => {
        let elm = document.createElement('a');
        elm.href = URL.createObjectURL(bytes);
        elm.setAttribute('download', `${product.name}_source.zip`);
        elm.click()
        return true;
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const deletePage = () => {
    let newProduct = { ...product, pages: [...product.pages.filter(y => deleteOpen === y ? null : y).filter(x => x)] };
    setProduct(newProduct);
    submitForce(newProduct);
    setPageIndex(null)
    setDeleteOpen(false)
  }

  const openPreview = () => {
    setLoading(true)
    submitThenPreview();
  }

  return <div className="flex flex-col h-screen max-h-screen overflow-y-auto sm:overflow-hidden overflow-x-hidden hide-scroll">

    {loading && <div className="fixed inset-0 bg-black/20">
      <div className="flex h-screen">
        <div className="w-28 m-auto">
          <svg aria-hidden="true" class="mr-2 w-28 h-28 text-gray-200 animate-spin dark:text-gray-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
        </div>

      </div></div>}
    <div className="bg-green-500 flex justify-between items-center p-4 shadow-lg">
      <div className="flex">

        <div onClick={() => navigate('/')} className="text-lg font-semibold  hover:text-neutral-100 text-white">
          <span className="cursor-pointer"><ChevronLeftIcon className="w-6 h-6 inline mr-1"></ChevronLeftIcon> Home</span>
        </div>
        <div onClick={() => setOpen(true)} className="text-lg font-semibold  hover:text-neutral-100 text-white">
          <span className="cursor-pointer ml-10"><CogIcon className="w-6 h-6 inline mr-1"></CogIcon> Settings</span>
        </div>
        <div onClick={() => setOpen(true)} className="text-lg font-semibold  hover:text-neutral-100 text-white">
          <span className="cursor-pointer ml-10"><ArrowDownIcon className="w-6 h-6 inline mr-1"></ArrowDownIcon> Logout</span>
        </div>
      </div>

      <div className="hidden sm:flex">
        <div
          onClick={() => { setShareOpen(true); setStep('0b14724f-017d-5dff-b3f6-e7562368ff97'); }}
          className={'text-neutral-800 mr-3 bg-neutral-200 cursor-pointer hover:bg-neutral-300 px-3 py-2 rounded-md text-sm font-medium'}
        >
          <ShareIcon className="mx-1 h-4 w-4 inline" aria-hidden="true" /> Share
        </div>
        <div
          onClick={() => { openPreview() }}
          className={'text-white bg-purple-500 cursor-pointer hover:bg-purple-500 px-3 py-2 rounded-md text-sm font-medium'}
        >
          <EyeIcon className="mx-1 h-4 w-4 inline" aria-hidden="true" /> Preview
        </div>
      </div>
      <div className="sm:hidden" onClick={() => setMobileOpen(!mobileOpen)}>
        <Bars3Icon className="text-white w-8 h-8"></Bars3Icon>
      </div>

    </div>
    {mobileOpen && <div className="flex flex-col bg-green-600">
      <div
        onClick={() => { setShareOpen(true); setStep('0b14724f-017d-5dff-b3f6-e7562368ff97'); }}
        className={'text-white cursor-pointer px-3 py-2 rounded-md text-sm font-medium'}
      >
        <ShareIcon className="mx-1 h-4 w-4 inline" aria-hidden="true" /> Share
      </div>
      <div
        onClick={() => { openPreview() }}
        className={'text-white  cursor-pointer  px-3 py-2 rounded-md text-sm font-medium'}
      >
        <EyeIcon className="mx-1 h-4 w-4 inline" aria-hidden="true" /> Preview
      </div>
    </div>}

    <div className="flex flex-col flex-1 sm:flex-row bg-neutral-700">
      {/* <div className=" bg-neutral-800 text-center flex sm:flex-col">
        <div onClick={() => setPagesOpen(!pagesOpen)} className={`${((pagesOpen && isMobile) || !isMobile) ? 'text-white bg-neutral-700' : 'bg-neutral-800 '}  text-neutral-100 text-xs p-2`}>
          Pages
          <DocumentIcon className="w-8 mx-auto hidden sm:block"></DocumentIcon>
        </div>
        <div onClick={() => setOpen(true)} className="cursor-pointer bg-neutral-800 text-neutral-100 text-xs p-2">
          Settings
          <CogIcon className="w-8 mx-auto hidden sm:block"></CogIcon>
        </div>
        <div onClick={() => setPublishOpen(true)} className="cursor-pointer bg-neutral-800 text-neutral-100 text-xs p-2">
          Get Code
          <CodeBracketIcon className="w-8 mx-auto hidden sm:block"></CodeBracketIcon>
        </div>
        {product.sourceDownloadAvailable && <div onClick={() => setOpen(true)} className="cursor-pointer bg-neutral-800 text-neutral-100 text-xs p-2">
          Download Code
          <DocumentArrowDownIcon className="w-8 mx-auto hidden sm:block"></DocumentArrowDownIcon>
        </div>}
        <div onClick={() => navigate('/logout')} className=" cursor-pointer bg-neutral-800 text-neutral-100 text-xs p-2">
          Logout
          <ArrowDownIcon className="w-8 mx-auto hidden sm:block"></ArrowDownIcon>
        </div>
      </div> */}
      {((pagesOpen && isMobile) || !isMobile) && <div className="flex flex-col hide-scroll overflow-y-auto sm:w-auto w-full sm:h-[calc(100vh-94px)] h-72 bg-white m-2 rounded-sm">
        <div className="text-center my-2 border-b border-neutral-200 text-neutral-800 text-lg font-bold ">App Pages</div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction={isMobile ? 'horizontal' : 'vertical'}>
            {(provided, snapshot) => (
              <div className="p-2 overflow-x-auto overflow-y-auto flex sm:flex-col "
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {product.pages?.map((x, i) => (
                  <Draggable draggableId={i + ''} index={i} key={i}>
                    {(provided, snapshot) => (
                      <div
                        onClick={() => { submit(); setPageIndex(i) }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`${i == pageIndex ? ' border-2 border-blue-500' : ''} m-1 mb-3 overflow-hidden shadow-md shadow-neutral-500 h-44 shrink-0`}
                      >
                        <div className={`${i == pageIndex ? 'opacity-100' : 'opacity-60'} relative w-[128px] sm:w-[179px] shadow-xl`}>
                          <div className="absolute w-full z-10">
                            <div className="bg-black/50 backdrop-blur-sm rounded-lg p-1 text-center shadow shadow-white/10 m-1">
                              <div className="truncate text-white text-sm font-semibold">{x.title}</div>
                              <div className="truncate text-neutral-50 text-xs hidden sm:block">{x.description}</div>
                            </div>
                          </div>

                          <Thumbnail scale="0.407" elements={x.template?.elements} product={product} page={x}></Thumbnail>

                        </div>
                      </div>
                    )}

                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="p-3 border-t border-neutral-200">
          <div onClick={() => { { setProduct({ ...product, pages: [...product.pages, { title: 'Untitled Page', new: true }] }); setPageIndex(product.pages.length); setStep('c72aa263-38fe-5a61-886b-8da321b5a123') } }} className={`hover:scale-[1.02] hover:shadow-xl transform transition duration-200 bg-purple-500 cursor-pointer p-2 sm:p-4 rounded shadow w-24 sm:w-full mx-auto`}>
            <PlusIcon className="w-8 mx-auto text-neutral-50"></PlusIcon>
          </div>
        </div>
      </div>}
      <div className="h-full flex-1 sm:overflow-y-auto hide-scroll bg-neutral-200">
        {page && <div className="sm:h-[calc(100vh-70px)]" key={pageIndex}>
          {page.template && <PageEditor setStep={setStep} currentStep={stepIndex} createdModalOpen={createdModalOpen || downloadOpen || open || !!deleteOpen || publishOpen || shareOpen} isMobile={isMobile} addPage={() => { setProduct({ ...product, pages: [...product.pages, { title: 'Untitled Page', new: true }] }); setPageIndex(product.pages.length); }} displayFireworks={displayFireworks} isGenerating={generatingContent} setDeleteOpen={setDeleteOpen} submit={submit} setPageIndex={setPageIndex} pageIndex={pageIndex} page={page} product={product} setProduct={setProduct}></PageEditor>}
          {!page.template && <TemplateSelector pageIndex={pageIndex} page={page} product={product} setProduct={setProduct}></TemplateSelector>}
        </div>}
      </div>
    </div>
    <ShareModal open={shareOpen} setOpen={setShareOpen} link={'https://app.vixba.com/app/' + product._id}></ShareModal>
    <PublishModal product={product} permissions={permissions} id={product._id} open={publishOpen} setOpen={setPublishOpen}></PublishModal>
    <Confirm open={!!deleteOpen} cancel={() => setDeleteOpen(false)} confirm={() => deletePage(deleteOpen)}></Confirm>
    <SettingsModal open={open} setOpen={setOpen} onSave={() => { setOpen(false); fetchData() }} onClose={() => setOpen(false)}></SettingsModal>
    <DownloadSourceModal open={downloadOpen} setOpen={setDownloadOpen}></DownloadSourceModal>
    <AppCreatedModal open={createdModalOpen} setOpen={setCreatedModalOpen}></AppCreatedModal>
  </div>
}

const PageEditor = ({ page, product, setProduct, pageIndex, submit, setPageIndex, setDeleteOpen, isGenerating, displayFireworks, addPage, isMobile, createdModalOpen, currentStep, setStep }) => {

  return <div className="h-full flex">
    <div className="w-80 p-2 justify-between bg-white flex-col hidden sm:flex">
      <div className="bg-white p-2">
        <label className="text-sm text-neutral-700">
          <b>Page Name</b>
        </label>
        <input
          value={page.title}
          onChange={(e) => setProduct({ ...product, pages: [...product.pages.map((x, i) => i == pageIndex ? { ...x, title: e.target.value } : x)] })}
          type="text"
          className="w-full block min-w-0 flex-1 rounded-md border-neutral-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
        />
        <label className="text-sm mt-2 text-neutral-700">
          <b>Description</b>
        </label>
        <textarea
          key={'desc_' + pageIndex}
          rows="4"
          value={page.description}
          onChange={(e) => setProduct({ ...product, pages: [...product.pages.map((x, i) => i == pageIndex ? { ...x, description: e.target.value } : x)] })}
          type="text"
          className="w-full block min-w-0 flex-1 rounded-md border-neutral-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
        />
        <hr className="my-2"></hr>
        {!page.isHome && <button
          onClick={() => { setProduct({ ...product, pages: [...product.pages.map((x, i) => i == pageIndex ? { ...x, isHome: true } : { ...x, isHome: false })] }) }}
          type="button"
          className="w-full text-center block my-2 items-center rounded-md border border-transparent bg-green-500 px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
        >
          Make This My Homepage
        </button>}
        {page.isHome && <div
          className="w-full text-center  block my-2 items-center rounded-md border border-transparent bg-purple-500 px-4 py-1 text-base font-medium text-white shadow-sm"
        >
          This is Your Homepage
        </div>}

        <label className="text-sm text-neutral-700 my-3 block">
          <b>Show A Home Button</b>
          <input
            checked={page.includeHomeButton}
            onChange={(e) => setProduct({ ...product, pages: [...product.pages.map((x, i) => i == pageIndex ? { ...x, includeHomeButton: e.target.checked } : x)] })}
            type="checkbox"
            className="inline-block w-4 ml-3 flex-1 rounded-md border-neutral-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
          />
        </label>
        <hr />
        {page?.template?.alts && <label className="text-sm mt-4 text-neutral-700">
          <b>Select Layout</b>
        </label>}
        {page?.template?.alts && <div className="grid grid-cols-2 gap-2">

          {(page?.altIndex === null || page?.altIndex === undefined) && <div className="cursor-pointer h-44 w-full overflow-hidden border border-blue-300">
            <Thumbnail scale="0.31" elements={page.template.elements} product={product} page={page}></Thumbnail>
          </div>}
          {page?.altIndex !== null && page?.altIndex !== undefined && <div onClick={() => setProduct({ ...product, pages: [...product.pages.map((y, j) => j == pageIndex ? { ...y, altIndex: null } : y)] })} className="opacity-60 cursor-pointer h-44 w-full overflow-hidden">
            <Thumbnail scale="0.31" elements={page.template.elements} product={product} page={page}></Thumbnail>
          </div>}

          {page?.template?.alts?.map((x, i) => <div onClick={() => setProduct({ ...product, pages: [...product.pages.map((y, j) => j == pageIndex ? { ...y, altIndex: i } : y)] })} >
            <div className={`cursor-pointer h-44 w-full overflow-hidden ${page?.altIndex === i ? 'border border-blue-300 ' : 'opacity-60'}`}>
              <Thumbnail scale="0.31" elements={x} product={product} page={page}></Thumbnail>
            </div>
          </div>)}

        </div>}
        {/* <button
          onClick={() => { setProduct({ ...product, pages: [...product.pages.map((x, i) => i == pageIndex ? { ...x, template: false } : x)] }); }}
          type="button"
          className="mt-3 inline-flex items-center rounded-md border border-transparent bg-neutral-200 px-4 py-1 text-base font-medium text-neutral-800 shadow-sm hover:bg-neutral-300 focus:outline-none focus:ring-2 focus:ring-neutral-300 focus:ring-offset-2"
        >
          Change Template
        </button> */}
        <hr className="my-3"/>
        <button
          onClick={() => { setDeleteOpen(page) }}
          type="button"
          className=" inline-flex items-center rounded-md border border-transparent bg-red-500 px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
        >
          Delete Page
        </button>
      </div>
    </div>
    <div className="relative w-0">
      <div className="w-64 m-5 absolute">
        <Guide steps={guideSteps} stepIndex={currentStep}></Guide>
      </div>

    </div>

    <div className="flex-1 sm:h-[calc(100vh-70px)] sm:flex block sm:justify-center hide-scroll">
      {pageIndex != 0 && !isGenerating && !createdModalOpen && <div className="relative z-20 select-none">
        <div onClick={() => { submit(); setPageIndex(+pageIndex - 1) }} className="hover:scale-[1.02] hover:shadow-xl transform transition duration-200 cursor-pointer absolute top-[200px] sm:top-[300px] rounded-full shadow left-0 sm:-left-28 w-12 h-12 sm:w-20 sm:h-20 bg-white text-neutral-600 p-2 sm:p-5">
          <ChevronLeftIcon></ChevronLeftIcon>
        </div>
      </div>}
      {isMobile && pageIndex < product.pages.length - 1 && !isGenerating && !createdModalOpen && <div className="relative z-30 select-none">
        <div onClick={() => { submit(); setPageIndex(+pageIndex + 1) }} className="hover:scale-[1.02] hover:shadow-xl transform transition duration-200 cursor-pointer absolute top-[200px] sm:top-[300px] rounded shadow right-0 sm:-right-28 w-12 h-12 sm:w-20 sm:h-20 bg-neutral-300/60 text-neutral-600 p-2 sm:p-5">
          <ChevronRightIcon></ChevronRightIcon>
        </div>
      </div>}
      {isMobile && pageIndex == product.pages.length - 1 && !isGenerating && !createdModalOpen && <div className="relative z-30 select-none">
        {product.pages.length < 3 && <span class="flex h-4 w-4 absolute -right-[117px] top-[295px] z-10">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
          <span class="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>
        </span>}
        <div onClick={() => { addPage(); setStep('c72aa263-38fe-5a61-886b-8da321b5a123') }} className="hover:scale-[1.02] hover:shadow-xl transform transition duration-200 cursor-pointer absolute top-[200px] sm:top-[300px] shadow rounded right-0 sm:-right-28 w-12 h-12 sm:w-20 sm:h-20 bg-neutral-300/60 text-neutral-600 p-2">
          <PlusIcon></PlusIcon>
        </div>
      </div>}
      <div className="block sm:hidden p-3">
        <label className="text-sm text-neutral-700">
          <b>Page Name:</b>
        </label>
        <input
          value={page.title}
          onChange={(e) => setProduct({ ...product, pages: [...product.pages.map((x, i) => i == pageIndex ? { ...x, title: e.target.value } : x)] })}
          type="text"
          className="w-full block min-w-0 flex-1 rounded-md border-neutral-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
        />
      </div>

      <div className={`${isGenerating ? 'pointer-events-none ' : ''} relative border-y-2 border-neutral-800 sm:border-[5px] rounded-none sm:rounded-[4vh] ss-font sm:mt-5 bg-white w-full sm:h-[667px] sm:w-[375px] sm:shadow-xl overflow-y-auto overflow-x-hidden hide-scroll`}>
        <div className="absolute left-[98px] z-40 hidden sm:block">
          <div className="fixed bg-neutral-800 rounded-b-full h-6 w-44">

          </div>
        </div>
        <div className="absolute bottom-3 left-[138px] z-40  hidden sm:block">
          <div className="fixed bg-neutral-800 rounded-full h-1  w-24 z-40">

          </div>
        </div>


        {displayFireworks && <div className="pyro absolute w-[500px] top-64">
          <div className="before"></div>
          <div className="after"></div>
        </div>}
        {isGenerating && <div className="relative w-full"><div className="z-40 p-5 top-3 left-[25%] absolute rounded shadow-xl bg-neutral-800/80 text-white flex flex-col"><div>Generating Content</div> <svg aria-hidden="true" class="mx-auto w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg></div></div>}

        {isGenerating && <div className="opacity-40">

          <TemplateRender isGenerating={true} elements={(page.altIndex !== null && page.altIndex !== undefined) ? page.template.alts[page.altIndex] : page.template.elements} product={product} page={page} updateContent={(val, id) => setProduct({ ...product, pages: [...product.pages.map(x => x === page ? { ...x, [id]: val } : x)] })}></TemplateRender>

        </div>}
        {!isGenerating && <TemplateRender elementEditClick={() => { setStep('356b5566-a4c3-5d6c-9a76-ebb7ca57d429'); }} elements={(page.altIndex !== null && page.altIndex !== undefined) ? page.template.alts[page.altIndex] : page.template.elements} product={product} page={page} updateContent={(val, id) => setProduct({ ...product, pages: [...product.pages.map(x => x === page ? { ...x, [id]: val } : x)] })}></TemplateRender>}

      </div>
      <div className="block sm:hidden p-3 pb-24">
        <label className="text-sm mt-2 text-neutral-700 block">
          <b>Description:</b>
        </label>
        <textarea
          key={'desc_' + pageIndex}
          rows="4"
          value={page.description}
          onChange={(e) => setProduct({ ...product, pages: [...product.pages.map((x, i) => i == pageIndex ? { ...x, description: e.target.value } : x)] })}
          type="text"
          className="w-full block min-w-0 flex-1 rounded-md border-neutral-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
        />
        <button
          onClick={() => { setProduct({ ...product, pages: [...product.pages.map((x, i) => i == pageIndex ? { ...x, template: false } : x)] }); }}
          type="button"
          className="mt-3 inline-flex items-center rounded-md border border-transparent bg-neutral-200 px-4 py-1 text-base font-medium text-neutral-800 shadow-sm hover:bg-neutral-300 focus:outline-none focus:ring-2 focus:ring-neutral-300 focus:ring-offset-2"
        >
          Change Template
        </button>
        <button
          onClick={() => { setDeleteOpen(page) }}
          type="button"
          className="float-right mt-3 inline-flex items-center rounded-md border border-transparent bg-red-500 px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
        >
          Delete
        </button>
      </div>
      {!isMobile && pageIndex < product.pages.length - 1 && !isGenerating && !createdModalOpen && <div className="relative select-none">
        <div onClick={() => { submit(); setPageIndex(+pageIndex + 1) }} className="hover:scale-[1.02] hover:shadow-xl transform transition duration-200 cursor-pointer absolute top-[200px] sm:top-[300px] rounded-full shadow right-0 sm:-right-28 w-12 h-12 sm:w-20 sm:h-20 bg-white text-neutral-600 p-2 sm:p-5">
          <ChevronRightIcon></ChevronRightIcon>
        </div>
      </div>}
      {!isMobile && pageIndex == product.pages.length - 1 && !isGenerating && !createdModalOpen && <div className="relative select-none">
        <div onClick={() => { addPage(); setStep('c72aa263-38fe-5a61-886b-8da321b5a123') }} className="hover:scale-[1.02] hover:shadow-xl transform transition duration-200 cursor-pointer absolute top-[300px] shadow rounded-full -right-28 w-20 h-20 bg-white text-neutral-600 p-5">
          <PlusIcon></PlusIcon>
        </div>
      </div>}
    </div>
  </div>
}

const TemplateSelector = ({ pageIndex, setProduct, product }) => {
  let navigate = useNavigate();
  const [pageTemplates, setPageTemplates] = useState([]);
  const [suggesting, setSuggesting] = useState(false);
  const [suggestionValues, setSuggestionValues] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await fetch(`${process.env.REACT_APP_API_URL}/page`, {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'bearer ' + localStorage.getItem("token")
        })
      }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
        setPageTemplates(x);
      })
    }
    fetchData();
  }, [])

  const saveSuggestion = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/product/page-request`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token"),
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ request: suggestionValues })
    }).then(res => { if (res.status === 401) navigate('/login'); return res.json() }).then(x => {
      setSuggesting(false);
      setSuggestionValues({})
    })
  }

  var templatesCategories = [...new Set(pageTemplates.map(item => item.category))];

  return <div>
    <div className="text-left mx-auto sm:h-[calc(100vh-70px)] sm:overflow-y-auto bg-neutral-100 shadow-xl p-5">
      <div className="mt-10">
        <h3 className="text-2xl leading-6 text-neutral-900 font-semibold text-center">Select Page Template</h3>
      </div>

      {templatesCategories.map(t => <>
        <div className="border-b border-neutral-200 pb-5 mb-5 mt-10 text-center">
          <h3 className="text-lg font-medium leading-6 text-neutral-900">{t ?? 'Other'}</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 ">

          {pageTemplates.filter(x => x.category === t).map(x => <div className={`p-2 flex`}>
            <div
              onClick={() => setProduct({ ...product, pages: [...product.pages.map((y, i) => i == pageIndex ? { ...y, new: true, template: x, title: y.title === "Untitled Page" ? x.name : y.title } : y)] })}
              className={`m-1 mb-3 overflow-hidden shadow-md shadow-neutral-500 h-96 shrink-0 w-[300px] mx-auto cursor-pointer`}
            >
              <div className={`relative w-[128px] sm:w-full shadow-xl `}>
                <div className="absolute w-full z-10">
                  <div className="bg-black/50 backdrop-blur-sm rounded-lg p-1 text-center shadow shadow-white/10 m-1">
                    <div className="truncate text-white text-sm font-semibold">{x.name}</div>
                    <div className="text-neutral-50 text-xs hidden sm:block">{x.description}</div>
                  </div>
                </div>

                <Thumbnail scale="0.67" elements={x.elements} product={product} page={x}></Thumbnail>

              </div>
            </div>





            {/* <div className={`p-2 rounded-md shadow bg-white hover:scale-[1.02] hover:shadow-xl transform transition duration-200 cursor-pointer`}>
              <div className={`grid grid-cols-2 h-44`}>
                <div className="overflow-hidden relative bg-white">
                  <Thumbnail scale="0.25" elements={x.elements} product={product} page={x}></Thumbnail>
                </div>
                <div className="flex-0 my-auto col-span-1">
                  <div className=" text-neutral-800 text-md font-semibold ml-2">{x.name}</div>
                  <div className=" text-neutral-600 text-sm ml-2">{x.description}</div>
                </div>
              </div>
            </div> */}
          </div>)}
        </div>
      </>)}
      {/* <div className="border-b border-neutral-200 pb-5 mb-5 mt-10 text-center">
        <h3 className="text-lg font-medium leading-6 text-neutral-900">Request a New Template</h3>
        <p className="text-sm font-medium text-neutral-700">If you don't see a template for a page you want to add, let us know! We're always looking to improve our range of templates and would love to hear your suggestions!</p>
      </div>
      {!suggesting && <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mb-10">
        <div onClick={() => setSuggesting(true)} className="cursor-pointer select-none bg-white hover:bg-neutral-100 rounded-md shadow-md p-5 border border-neutral-100">
          Request Something Else<br />
          <span className="text-neutral-600">Request a page template</span>
        </div>
      </div>}
      {suggesting && <div className="mb-10">
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-neutral-200 sm:pt-5">
          <label htmlFor="name" className="block text-sm font-medium text-neutral-700 sm:mt-px sm:pt-2">
            Page Name
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <div className="flex max-w-lg rounded-md shadow-sm">
              <input
                value={suggestionValues.name}
                onChange={(e) => setSuggestionValues({ ...suggestionValues, name: e.target.value })}
                type="text"
                className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-neutral-200 sm:pt-5">
          <label htmlFor="description" className="block text-sm font-medium text-neutral-700 sm:mt-px sm:pt-2">
            Description
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <textarea
              rows={3}
              className="block w-full max-w-lg rounded-md border-neutral-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              value={suggestionValues.description}
              onChange={(e) => setSuggestionValues({ ...suggestionValues, description: e.target.value })}
            />
            <p className="mt-2 text-sm text-neutral-500">Description of the page and it's features.</p>
          </div>
        </div>
        <div className="pt-5 flex">
          <div className="flex justify-end">
            <button
              onClick={() => { setSuggestionValues({}); setSuggesting(false); }}
              className="rounded-md border border-neutral-300 bg-white py-2 px-4 text-sm font-medium text-neutral-700 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              onClick={() => { saveSuggestion(); }}
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
      </div>} */}
    </div>

  </div>
}

function SettingsModal({ open, setOpen, onSave }) {


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-0 pt-0 pb-0 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <Settings onSave={onSave} onClose={() => setOpen(false)}></Settings>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
function ShareModal({ open, setOpen, link }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div className="text-center text-neutral-800 text-lg font-semibold">Share Your prototype With Others</div>
                <p className="text-neutral-800 text-center mt-6">
                  Use this link to share you prototype and start getting feedback on your idea!
                </p>
                <p className="text-neutral-800 mt-5 text-center text-xl hover:underline font-semibold">
                  <a target='_blank' href={link}><EyeIcon className="w-6 h-6 inline"></EyeIcon> View App</a>
                </p>
                <CopyToClipboard text={link}
                  onCopy={() => toast.info("Copied To Clipboard!")}>
                  <p className="cursor-pointer text-neutral-800 mt-5 text-center text-xl hover:underline font-semibold"><ClipboardDocumentIcon className="w-6 h-6 inline"></ClipboardDocumentIcon> Copy URL</p>
                </CopyToClipboard>
              </Dialog.Panel>
            </Transition.Child>
            <ToastContainer position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light" />
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
function AppCreatedModal({ open, setOpen, link }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div className="text-center text-neutral-800 text-lg font-semibold">Prototype Created!</div>
                <p className="text-neutral-800 text-center mt-6">
                  You've created your app prototype and generated your first page!
                </p>
                <p className="text-neutral-800 text-center mt-6">
                  Add more pages to flesh out your user journey, then share with friends or investors to start getting feedback
                </p>
                <p className="text-neutral-800 text-center mt-6">
                  Vixba is made by a small team and we're always happy to help out! Get in touch if you have any questions or want any help
                </p>
                <p className="text-neutral-800 text-center my-6">
                  Happy Building!
                </p>
                <div className="flex">
                  <Button onClick={() => setOpen(false)} className="mx-auto" text="Keep Building"></Button>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
function PublishModal({ open, setOpen, id, permissions, product }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
                <Options close={() => setOpen(false)} product={product} permissions={permissions} id={id}></Options>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
function Options({ id, permissions, product, close }) {
  const select = async (type) => {
    await fetch(`${process.env.REACT_APP_API_URL}/payment/${id}/${type}`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'bearer ' + localStorage.getItem("token")
      })
    }).then(res => { return res.json() }).then(x => {
      window.location.replace(x.url)
    })
  }

  const [option, setOption] = useState(false);

  if (!option) {
    return (
      <div className="mx-auto max-w-7xl bg-white py-0 sm:py-12 px-0 sm:px-8">
        <XMarkIcon onClick={() => close()} className="h-4 w-4 float-right rounded-full border border-neutral-200 cursor-pointer text-neutral-500 hover:text-neutral-700"></XMarkIcon>
        <h2 className="text-3xl font-bold tracking-tight text-neutral-900 sm:text-5xl sm:leading-none lg:text-6xl">
          Get Your Code
        </h2>
        <p className="mt-6 max-w-2xl text-xl text-neutral-500">
          Get access to your prototype source code, or turn it into a real product!
        </p>

        <div className="mt-12 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
          <div
            className="relative flex flex-col rounded-2xl border border-neutral-200 bg-white p-8 shadow-sm"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-neutral-900">I just want the code for my Mock-Up</h3>

              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                <li className="flex">
                  <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                  <span className="ml-3 text-neutral-500">Self-Host Your Prototype</span>
                </li>
                <li className="flex">
                  <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                  <span className="ml-3 text-neutral-500">Download Front-End Source Code</span>
                </li>
                <li className="flex">
                  <XMarkIcon className="h-6 w-6 flex-shrink-0 text-red-500" aria-hidden="true" />
                  <span className="ml-3 text-neutral-500">No Backend Code</span>
                </li>
              </ul>
            </div>

            <div
              onClick={() => setOption(1)}
              className={'bg-green-500 cursor-pointer text-white hover:bg-green-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
            >
              Get Access
            </div>
          </div>

          <div
            className="relative flex flex-col rounded-2xl border border-neutral-200 bg-white p-8 shadow-sm"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-neutral-900">My Tech resource can finish building my Mock-Up into a full application</h3>

              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                <li className="flex">
                  <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                  <span className="ml-3 text-neutral-500">Download Front and Back-End Source Code</span>
                </li>
                <li className="flex">
                  <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                  <span className="ml-3 text-neutral-500">Download Scope Document</span>
                </li>
                <li className="flex">
                  <XMarkIcon className="h-6 w-6 flex-shrink-0 text-red-500" aria-hidden="true" />
                  <span className="ml-3 text-neutral-500">No Project Manager</span>
                </li>
              </ul>
            </div>

            <div
              onClick={() => setOption(2)}
              className={'bg-green-500 cursor-pointer text-white hover:bg-green-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
            >
              Download Source Code
            </div>
          </div>

          <div
            className="relative flex flex-col rounded-2xl border border-neutral-200 bg-white p-8 shadow-sm"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-neutral-900">I want Verticode To Build my Product</h3>

              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                <li className="flex">
                  <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                  <span className="ml-3 text-neutral-500">Fully Developed By Verticode's In-House Development Team</span>
                </li>
                <li className="flex">
                  <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                  <span className="ml-3 text-neutral-500">Full Code Ownership</span>
                </li>
                <li className="flex">
                  <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                  <span className="ml-3 text-neutral-500">Hosted On Verticode's Servers With A Custom Domain</span>
                </li>
                <li className="flex">
                  <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                  <span className="ml-3 text-neutral-500">Dedicated Project Manager</span>
                </li>
              </ul>
            </div>

            <div onClick={() => setOption(3)}
              className={'bg-green-500 cursor-pointer text-white hover:bg-green-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
            >
              Start My Build
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (option === 1) {
    return <div className="mx-auto max-w-7xl bg-white py-0 sm:py-12 px-0 sm:px-8">
      <XMarkIcon onClick={() => close()} className="h-4 w-4 float-right rounded-full border border-neutral-200 cursor-pointer text-neutral-500 hover:text-neutral-700"></XMarkIcon>
      <ArrowLeftIcon onClick={() => setOption(false)} className="absolute left-5 top-5 text-neutral-600 hover:text-neutral-700 cursor-pointer w-8 h-8"></ArrowLeftIcon>
      <h2 className="mt-10 sm:mt-0 text-3xl font-bold tracking-tight text-neutral-900 sm:text-5xl sm:leading-none lg:text-6xl">
        Access Prototype Source Code
      </h2>
      <p className="mt-6 max-w-2xl text-xl text-neutral-500">
        Host your prototype on your own server or domain, or use your prototype source code to kick start your product development.
      </p>

      <div className="mt-12 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
        <div
          className="relative flex flex-col rounded-2xl border border-neutral-200 bg-white p-8 shadow-sm"
        >
          <div className="flex-1">
            <h3 className="text-xl font-semibold text-neutral-900">Share On A URL</h3>
            <p className="mt-4 flex items-baseline text-neutral-900">
              <span className="text-5xl  tracking-tight line-through">£25</span><span className="text-5xl font-bold tracking-tight">FREE</span>
              {/* <span className="ml-1 text-xl font-semibold">per month</span> */}
            </p>
            <p className="mt-6 text-neutral-500">Easily share your Mock-Up via URL</p>

            {/* Feature list */}
            <ul role="list" className="mt-6 space-y-6">
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Easy-to-Share URL</span>
              </li>
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Unlimited Sharing</span>
              </li>
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Unlimited Traffic</span>
              </li>
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Hosted for Free</span>
              </li>
            </ul>
          </div>
          <div title="You're Already A Pro User"
            className={'bg-neutral-500 text-white mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
          >
            Free During Beta
          </div>
          {/* {permissions.isPro && <div title="You're Already A Pro User"
            className={'bg-neutral-500 text-white mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
          >
            Upgrade To Pro To Share
          </div>}

          {!permissions.isPro && <div onClick={() => select('pro')}
            className={'bg-green-500 cursor-pointer text-white hover:bg-green-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
          >
            Upgrade To Pro To Share
          </div>} */}
        </div>

        <div
          className="relative flex flex-col rounded-2xl border border-neutral-200 bg-white p-8 shadow-sm"
        >
          <div className="flex-1">
            <h3 className="text-xl font-semibold text-neutral-900">Download Source Code</h3>
            <p className="mt-4 flex items-baseline text-neutral-900">
              <span className="text-5xl font-bold tracking-tight">£300</span>
              <span className="ml-1 text-xl font-semibold">one off</span>
            </p>
            <p className="mt-6 text-neutral-500">Generate and download HTML source code for your prototype to take to a developer and self host.</p>

            {/* Feature list */}
            <ul role="list" className="mt-6 space-y-6">
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Own 100% of The Generated Front-End Source Code</span>
              </li>
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Use Your Code as a Starting Point for Full Development</span>
              </li>
            </ul>
          </div>

          {product.sourceDownloadAvailable && <span title="You've already bought prototype source code for this product"
            className={'bg-neutral-500 text-white mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
          >
            Generate Source Code
          </span>}
          {!product.sourceDownloadAvailable && <span onClick={() => select('proto_source')}
            className={'bg-green-500 cursor-pointer text-white hover:bg-green-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
          >
            Generate Source Code
          </span>}
        </div>
      </div>
    </div>
  }
  if (option === 2) {
    return <div className="mx-auto max-w-7xl bg-white py-0 sm:py-12 px-0 sm:px-8">
      <XMarkIcon onClick={() => close()} className="h-4 w-4 float-right rounded-full border border-neutral-200 cursor-pointer text-neutral-500 hover:text-neutral-700"></XMarkIcon>
      <ArrowLeftIcon onClick={() => setOption(false)} className="absolute left-5 top-5 text-neutral-600 hover:text-neutral-700 cursor-pointer w-8 h-8"></ArrowLeftIcon>
      <h2 className="mt-10 sm:mt-0 text-3xl font-bold tracking-tight text-neutral-900 sm:text-5xl sm:leading-none lg:text-6xl">
        Download Your Code
      </h2>
      <p className="mt-6 max-w-2xl text-xl text-neutral-500">
        Take the first step to building your fully functioning product by instantly downloading source code or a scoping document
      </p>

      <div className="mt-12 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
        <div
          className="relative flex flex-col rounded-2xl border border-neutral-200 bg-white p-8 shadow-sm"
        >
          <div className="flex-1">
            <h3 className="text-xl font-semibold text-neutral-900">Download Project Source Code</h3>
            <p className="mt-4 flex items-baseline text-neutral-900">
              <span className="text-5xl font-bold tracking-tight">£900</span>
              <span className="ml-1 text-xl font-semibold">one off</span>
            </p>
            <p className="mt-6 text-neutral-500">Download source code to kick start your product development.</p>

            {/* Feature list */}
            <ul role="list" className="mt-6 space-y-6">
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Own 100% of The Generated Source Code</span>
              </li>
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Save 5-10 Days Of Development Time</span>
              </li>
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Standard react.js, node.js, express.js and mongoDB tech stack</span>
              </li>
            </ul>
          </div>

          {product.fullCodeDownloadAvailable && <div title="You've already bought full source code for this product"
            className={'bg-neutral-500  text-white  mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
          >
            Download Source Code
          </div>}

          {!product.fullCodeDownloadAvailable && <div onClick={() => select('source_code')}
            className={'bg-green-500 cursor-pointer text-white hover:bg-green-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
          >
            Download Source Code
          </div>}
        </div>

        <div
          className="relative flex flex-col rounded-2xl border border-neutral-200 bg-white p-8 shadow-sm"
        >
          <div className="flex-1">
            <h3 className="text-xl font-semibold text-neutral-900">Download Scoping PDF Document</h3>
            <p className="mt-4 flex items-baseline text-neutral-900">
              <span className="text-5xl font-bold tracking-tight">£250</span>
              <span className="ml-1 text-xl font-semibold">one off</span>
            </p>
            <p className="mt-6 text-neutral-500">Download an agency-level scoping document including wire frames for all pages.</p>

            {/* Feature list */}
            <ul role="list" className="mt-6 space-y-6">
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Scoping Document Ready To Hand Over To Development Agencies For Quotes</span>
              </li>
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Unlimited Pages</span>
              </li>
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Wire frames included in document</span>
              </li>
            </ul>
          </div>

          {product.scopeDocumentAvailable && <div title="You've already bought a scope document for this product"
            className={'bg-neutral-500 text-white mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
          >
            Generate Scoping Document
          </div>}
          {!product.scopeDocumentAvailable && <div onClick={() => select('scope_doc')}
            className={'bg-green-500 cursor-pointer text-white hover:bg-green-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
          >
            Generate Scoping Document
          </div>}
        </div>

        <div
          className="relative flex flex-col rounded-2xl border border-neutral-200 bg-white p-8 shadow-sm"
        >
          <div className="flex-1">
            <h3 className="text-xl font-semibold text-neutral-900">Source Code and Scoping Document</h3>
            <p className="mt-4 flex items-baseline text-neutral-900">
              <span className="text-5xl font-bold tracking-tight">£1050</span>
              <span className="ml-1 text-xl font-semibold">one off</span>
            </p>
            <p className="mt-6 text-neutral-500">Be ready to get development quotes with both your source code & scoping document</p>

            {/* Feature list */}
            <ul role="list" className="mt-6 space-y-6">
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">100% ownership of source code</span>
              </li>
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Unlimited Pages</span>
              </li>
              <li className="flex">
                <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                <span className="ml-3 text-neutral-500">Everything You Need To Get A Development Quote</span>
              </li>
            </ul>
          </div>

          {(product.scopeDocumentAvailable || product.fullCodeDownloadAvailable) && <div title="You've already bought part of this bundle"
            className={'bg-neutral-500  text-white mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
          >
            Generate Code & Scope
          </div>}
          {(!product.scopeDocumentAvailable && !product.fullCodeDownloadAvailable) && <div onClick={() => select('source_code_scope_doc')}
            className={'bg-green-500 cursor-pointer text-white hover:bg-green-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'}
          >
            Generate Code & Scope
          </div>}
        </div>
      </div>
    </div>
  }
  if (option === 3) {
    return <div className="mx-auto max-w-7xl bg-white py-0 sm:py-12 px-0 sm:px-8">
      <XMarkIcon onClick={() => close()} className="h-4 w-4 float-right rounded-full border border-neutral-200 cursor-pointer text-neutral-500 hover:text-neutral-700"></XMarkIcon>
      <ArrowLeftIcon onClick={() => setOption(false)} className="absolute left-5 top-5 text-neutral-600 hover:text-neutral-700 cursor-pointer w-8 h-8"></ArrowLeftIcon>
      <h2 className="mt-10 sm:mt-0 text-3xl font-bold tracking-tight text-neutral-900 sm:text-4xl sm:leading-none lg:text-5xl">
        Build Your Product With Verticode
      </h2>
      <p className="mt-6  text-xl text-neutral-500">
        Use Verticode’s in-house technology team to build your project in the quickest and most affordable way possible.
      </p>
      <p className="mt-6 text-lg text-neutral-700">
        <div className="text-xl text-neutral-800 font-semibold mb-3">Verticode's proprietary development toolkit enables us to build your application:</div>

        <ul>
          <li className="list-disc ml-10">10x Faster than Development Agencies</li>
          <li className="list-disc ml-10">70% Cheaper than Development Agencies</li>
          <li className="list-disc ml-10">With Full Functionality capabilities</li>
        </ul>

      </p>

      <p className="mt-6 text-lg text-neutral-700">
        <div className="text-xl text-neutral-800 font-semibold mb-3"> Plus, you’ll benefit from:</div>

        <ul>
          <li className="list-disc ml-10">Full Code (IP) Ownership</li>
          <li className="list-disc ml-10">Free 2hr Scoping Consultation</li>
          <li className="list-disc ml-10">Included Hosting On Our Servers With A Custom Domain</li>
          <li className="list-disc ml-10">Unlimited Iterations and Modifications</li>
          <li className="list-disc ml-10">Dedicated Project Manager</li>
        </ul>

      </p>
      <p className="mt-6 text-xl text-neutral-500 text-center">
        <div className="font-bold tracking-tight text-lg text-neutral-800">*Prices Vary Based on Complexity*</div>
        <div className="font-bold text-3xl text-neutral-800">£5,000 - £20,000</div>
        <div className="text-md text-neutral-600">+ £150pm Maintenance Fee</div>
      </p>
      <div className="mt-5 flex">
        <a target={'_blank'} href="https://calendly.com/verticode/30min" title="You've already bought part of this bundle"
          className={'mx-auto bg-green-500 hover:bg-green-600 text-white mt-8 w-86 py-3 px-6 border border-transparent rounded-md text-center font-medium'}
        >
          Lets Get Started
        </a>
      </div>

    </div>
  }
}
function DownloadSourceModal({ open, setOpen }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div className="text-center text-neutral-800 text-lg font-semibold">Your Source Code Is Downloading Now!</div>
                <p className="text-neutral-800 text-center mt-6">
                  It's a good idea to read the included README.md file. It has some useful information on how to get started with your source code.
                </p>
                <p className="text-neutral-800 text-center mt-6">
                  If you have any issues, quires, need some help, or just want some advice, contact our technical team at <a href="mailto:technial@vixba.com">technial@vixba.com</a>
                </p>
                <p className="text-neutral-800 text-center mt-6">
                  We're more than happy to help out!
                </p>
              </Dialog.Panel>
            </Transition.Child>
            <ToastContainer position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light" />
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}