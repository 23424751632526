import { BoltIcon } from "@heroicons/react/24/outline";

const EditableContent = ({ defaultContent, id, page }) => {
    return <div className="text-neutral-200 bg-neutral-200 rounded-xl inline-block">{page[id] ?? defaultContent}</div>
}

export default function Thumbnail(props) {
    const { elements, product } = props;
    return <div className="pointer-events-none relative w-0 h-0"><div className={`bg-white h-[850px] w-[448px] absolute scale-[0.285]  origin-top-left ${props.scale ? `sm:scale-[${props.scale}]` : 'sm:scale-[0.24]'} `}><ElementList {...props} elements={elements} i={''}></ElementList></div></div>
}

const ElementList = (props) => {
    const { elements } = props;
    if (elements)
        return elements.map(x => {

            if (x && x.isArray) {
                return <>
                    {
                        (props.page[x.id] ?? [{}]).map((y, i) => {
                            return <Element array_type_index={y.type_index ?? 0} {...props} element={x} i={i > 0 ? i : undefined}></Element>
                        })
                    } </>
            }
            else {
                return <Element {...props} element={x}></Element>

            }
        }
        )
}

const getStyle = (pt, st, tt, pb, sb, tb, pc, sc, tc) => {
    let style = {};
    if (pt) style.color = pc;
    if (st) style.color = sc;
    if (tt) style.color = tc;

    if (pb) style.backgroundColor = pc;
    if (sb) style.backgroundColor = sc;
    if (tb) style.backgroundColor = tc;

    return style
}

const Element = (props) => {
    const { element, product } = props;

    if (!element) return null;
    if (props.array_type_index !== undefined && element.af_type_index !== undefined && +element.af_type_index != props.array_type_index) return null
    let CustomElement = element.element ? element.element : 'div';
    if (CustomElement === "button") CustomElement = "div"
    if (CustomElement == "img") return <div className={element.className + ' bg-neutral-600/70'}></div>
    if (CustomElement == "img" || CustomElement == "input" || CustomElement == "textarea") return <CustomElement className={element.className}></CustomElement>
    return <CustomElement style={getStyle(element.primary_text, element.secondary_text, element.tertiary_text, element.primary_bg, element.secondary_bg, element.tertiary_bg, product.primaryColour, product.secondaryColour, product.tertiaryColour)} className={element.className + ''}>
        {element.content && <EditableContent  {...props} defaultContent={element.content.trim()} id={element.id + '' + props.i} />}
        <ElementList {...props} elements={element.children} i={props.i}></ElementList>
    </CustomElement>
}